import { Flex } from '@tremor/react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export function TermsPage() {
    const terms = `
# Terms of Service
`
    return (
        <Flex className="px-96 bg-gray-100">
            <Markdown
                className="p-4 text-black bg-white rounded-md"
                remarkPlugins={[remarkGfm]}
            >
                {terms}
            </Markdown>
        </Flex>
    )
}
