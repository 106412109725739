/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface EntitiesAttachment {
    content?: string
    fileType?: string
    filename?: string
    url?: string
}

export interface EntitiesCallVoice {
    chatID?: string
    model?: string
    voiceBytesBase64?: string
}

export interface EntitiesChatItem {
    createdAt?: string
    id?: string
    title?: string
}

export interface EntitiesError {
    message?: string
}

export interface EntitiesMeResponse {
    balance?: number
}

export interface EntitiesMessageItem {
    ID?: string
    attachments?: EntitiesAttachment[]
    chatID?: string
    content?: string
    createdAt?: string
    role?: string
}

export interface EntitiesRequestMessage {
    callVoice?: EntitiesCallVoice
    sendMessage?: EntitiesSendMessage
    subscribeToChatMessages?: EntitiesSubscribeToChatMessages
    subscribeToChats?: EntitiesSubscribeToChats
}

export interface EntitiesResponseMessage {
    callVoice?: EntitiesCallVoice
    chatItem?: EntitiesChatItem
    error?: EntitiesError
    messageItem?: EntitiesMessageItem
    messageStreamItem?: EntitiesStreamMessageItem
    switchChatItem?: EntitiesSwitchChatItem
}

export interface EntitiesSendMessage {
    ID?: string
    attachments?: EntitiesAttachment[]
    chatID?: string
    content?: string
    model?: string
}

export interface EntitiesSendTicketRequest {
    content?: string
    id?: number
    newTicket?: boolean
    title?: string
}

export interface EntitiesStreamMessageItem {
    ID?: string
    chatID?: string
    contentToBeAppended?: string
    createdAt?: string
    role?: string
}

export interface EntitiesSubscribeToChatMessages {
    chatID?: string
}

export type EntitiesSubscribeToChats = object

export interface EntitiesSwitchChatItem {
    chatID?: string
}

export interface EntitiesTTSRequest {
    inputText?: string
}

export interface EntitiesTicket {
    created_at?: string
    id?: number
    title?: string
    updated_at?: string
    user_id?: string
}

export interface EntitiesTicketMessage {
    created_at?: string
    id?: number
    message_content?: string
    receiving?: boolean
    updated_at?: string
    user_id?: string
}

import type {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    HeadersDefaults,
    ResponseType,
} from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
    extends Omit<
        AxiosRequestConfig,
        'data' | 'params' | 'url' | 'responseType'
    > {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean
    /** request path */
    path: string
    /** content type of request body */
    type?: ContentType
    /** query params */
    query?: QueryParamsType
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType
    /** request body */
    body?: unknown
}

export type RequestParams = Omit<
    FullRequestParams,
    'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
    extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    securityWorker?: (
        securityData: SecurityDataType | null
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
    secure?: boolean
    format?: ResponseType
}

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
    Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
    public instance: AxiosInstance
    private securityData: SecurityDataType | null = null
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
    private secure?: boolean
    private format?: ResponseType

    constructor({
        securityWorker,
        secure,
        format,
        ...axiosConfig
    }: ApiConfig<SecurityDataType> = {}) {
        this.instance = axios.create({
            ...axiosConfig,
            baseURL: axiosConfig.baseURL || 'https://salehkhazaei.ir',
        })
        this.secure = secure
        this.format = format
        this.securityWorker = securityWorker
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data
    }

    protected mergeRequestParams(
        params1: AxiosRequestConfig,
        params2?: AxiosRequestConfig
    ): AxiosRequestConfig {
        const method = params1.method || (params2 && params2.method)

        return {
            ...this.instance.defaults,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...((method &&
                    this.instance.defaults.headers[
                        method.toLowerCase() as keyof HeadersDefaults
                    ]) ||
                    {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        }
    }

    protected stringifyFormItem(formItem: unknown) {
        if (typeof formItem === 'object' && formItem !== null) {
            return JSON.stringify(formItem)
        } else {
            return `${formItem}`
        }
    }

    protected createFormData(input: Record<string, unknown>): FormData {
        if (input instanceof FormData) {
            return input
        }
        return Object.keys(input || {}).reduce((formData, key) => {
            const property = input[key]
            const propertyContent: any[] =
                property instanceof Array ? property : [property]

            for (const formItem of propertyContent) {
                const isFileType =
                    formItem instanceof Blob || formItem instanceof File
                formData.append(
                    key,
                    isFileType ? formItem : this.stringifyFormItem(formItem)
                )
            }

            return formData
        }, new FormData())
    }

    public request = async <T = any, _E = any>({
        secure,
        path,
        type,
        query,
        format,
        body,
        ...params
    }: FullRequestParams): Promise<AxiosResponse<T>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {}
        const requestParams = this.mergeRequestParams(params, secureParams)
        const responseFormat = format || this.format || undefined

        if (
            type === ContentType.FormData &&
            body &&
            body !== null &&
            typeof body === 'object'
        ) {
            body = this.createFormData(body as Record<string, unknown>)
        }

        if (
            type === ContentType.Text &&
            body &&
            body !== null &&
            typeof body !== 'string'
        ) {
            body = JSON.stringify(body)
        }

        return this.instance.request({
            ...requestParams,
            headers: {
                ...(requestParams.headers || {}),
                ...(type ? { 'Content-Type': type } : {}),
            },
            params: query,
            responseType: responseFormat,
            data: body,
            url: path,
        })
    }
}

/**
 * @title Platform API
 * @version 1.0
 * @baseUrl https://salehkhazaei.ir
 * @contact
 */
export class Api<
    SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
    api = {
        /**
         * No description
         *
         * @tags user
         * @name V1MeList
         * @summary GetMe
         * @request GET:/api/v1/me
         */
        v1MeList: (params: RequestParams = {}) =>
            this.request<EntitiesMeResponse, any>({
                path: `/api/v1/me`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags chat
         * @name V1SttCreate
         * @summary STT
         * @request POST:/api/v1/stt
         */
        v1SttCreate: (
            data: {
                /**
                 * File content
                 * @format binary
                 */
                file: File
            },
            params: RequestParams = {}
        ) =>
            this.request<string, any>({
                path: `/api/v1/stt`,
                method: 'POST',
                body: data,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user
         * @name V1TicketList
         * @summary ListTicket
         * @request GET:/api/v1/ticket
         */
        v1TicketList: (params: RequestParams = {}) =>
            this.request<EntitiesTicket[], any>({
                path: `/api/v1/ticket`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user
         * @name V1TicketCreate
         * @summary CreateTicket
         * @request POST:/api/v1/ticket
         */
        v1TicketCreate: (
            request: EntitiesSendTicketRequest,
            query?: {
                /** Service name */
                service?: string
            },
            params: RequestParams = {}
        ) =>
            this.request<number, any>({
                path: `/api/v1/ticket`,
                method: 'POST',
                query: query,
                body: request,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags user
         * @name V1TicketDetail
         * @summary GetTicket
         * @request GET:/api/v1/ticket/{id}
         */
        v1TicketDetail: (id: string, params: RequestParams = {}) =>
            this.request<EntitiesTicketMessage[], any>({
                path: `/api/v1/ticket/${id}`,
                method: 'GET',
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags chat
         * @name V1TtsCreate
         * @summary TTS
         * @request POST:/api/v1/tts
         */
        v1TtsCreate: (
            request: EntitiesTTSRequest,
            params: RequestParams = {}
        ) =>
            this.request<string, any>({
                path: `/api/v1/tts`,
                method: 'POST',
                body: request,
                type: ContentType.Json,
                ...params,
            }),

        /**
         * No description
         *
         * @tags file
         * @name V1UserFilesUploadUpdate
         * @summary UploadUserFile
         * @request PUT:/api/v1/user/files/upload
         */
        v1UserFilesUploadUpdate: (
            query: {
                /** Filename */
                filename: string
                /** Filetype */
                filetype: string
            },
            data: {
                /** File content */
                file: File
            },
            params: RequestParams = {}
        ) =>
            this.request<string, any>({
                path: `/api/v1/user/files/upload`,
                method: 'PUT',
                query: query,
                body: data,
                type: ContentType.FormData,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags file
         * @name V1UserFilesDownloadDetail
         * @summary DownloadFile
         * @request GET:/api/v1/user/files/{userId}/{fileId}/download
         */
        v1UserFilesDownloadDetail: (
            userId: string,
            fileId: string,
            params: RequestParams = {}
        ) =>
            this.request<number[], any>({
                path: `/api/v1/user/files/${userId}/${fileId}/download`,
                method: 'GET',
                ...params,
            }),

        /**
         * No description
         *
         * @tags ws
         * @name V1WsList
         * @summary RegisterWS
         * @request GET:/api/v1/ws
         */
        v1WsList: (
            request: EntitiesRequestMessage,
            params: RequestParams = {}
        ) =>
            this.request<EntitiesResponseMessage, any>({
                path: `/api/v1/ws`,
                method: 'GET',
                body: request,
                type: ContentType.Json,
                format: 'json',
                ...params,
            }),
    }
}
