import React, { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import dayjs from 'dayjs'
import { useAuth } from './utilities/auth'
import { authHostname } from './api/ApiConfig'
import { LLMHive } from './sdk'
import { notificationAtom } from './components/Layout/notification'
import { TermsPage } from './page/docs/terms'
import { PrivacyPage } from './page/docs/policy'

interface IAuthProviderWithNavigate {
    children?: React.ReactNode
}

export const AuthProviderWithNavigate = ({
    children,
}: IAuthProviderWithNavigate) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [notifs, setNotifs] = useAtom(notificationAtom)

    const [locationSearchParams, setSearchParams] = useSearchParams()
    const { isAuthenticated, isLoading, loginWithCode, error } = useAuth()
    const page = window.location.pathname.split('/')

    useEffect(() => {
        if (
            locationSearchParams.get('code') !== null &&
            locationSearchParams.get('code') !== '' &&
            !isLoading &&
            error.message === '' &&
            !isAuthenticated
        ) {
            loginWithCode(locationSearchParams.get('code') || '')
        }

        if (!isAuthenticated && page[1] !== 'callback') {
            const callback = `${window.location.origin}/callback`

            const searchParams = new URLSearchParams()
            searchParams.append('client_id', 'public-client')
            searchParams.append('redirect_uri', callback)
            searchParams.append('scope', 'openid email profile federated:id')
            searchParams.append('response_type', 'code')
            searchParams.toString()

            sessionStorage.setItem('callbackURL', window.location.href)

            window.location.href = `${authHostname()}/dex/auth?${searchParams.toString()}`
        }

        if (isAuthenticated) {
            LLMHive.connect(navigate, pathname, (text) => {
                setNotifs([
                    ...notifs,
                    { text, type: 'alert', createdAt: dayjs() },
                ])
            })
        }
    }, [])
    if (page[1] === 'terms') {
        return <TermsPage />
    }
    if (page[1] === 'privacy') {
        return <PrivacyPage />
    }

    return (
        <>
            <span />
            {children}
        </>
    )
}
